
import { Component } from 'vue-property-decorator';
import Editable from '@/mixins/Editable';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VWrapper from '@/components/shared/VWrapper.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { SubscriptionCategory as SC } from '@/interfaces/models/SubscriptionCategories';
import SubscriptionCategoriesForm from '@/components/subscriptionCategories/SubscriptionCategoriesForm.vue';
import EditSubscriptionCategoriesLoader from '@/components/subscriptionCategories/EditSubscriptionCategoriesLoader.vue';
import { Subscription } from '@/interfaces/models/Subscription';

const venue = namespace('venue');
const foodcard = namespace('foodcard');

@Component({
  components: { VWrapper, EditSubscriptionCategoriesLoader, SubscriptionCategoriesForm },
})
export default class EditSubscriptionCategories extends mixins(Editable, StackedForm, Notification) {
  @venue.State('items') public venues!: Venue[];

  @foodcard.State('subscriptionCategories') public subscriptionCategories!: SC[];
  @foodcard.State('activeSubscriptionCategory') public activeSubscriptionCategory!: SC;
  @foodcard.State('subscriptions') public subscriptions!: Subscription[];

  @foodcard.Action('setActiveSubscriptionCategory') public setActive!: any;
  @foodcard.Action('updateSubscriptionCategory') public update!: any;
  @foodcard.Action('uploadSubscriptionCategoryImage') public uploadImage!: any;

  @foodcard.Action('storeSubscriptionCategory') public store!: any;

  public $refs!: {
    form: InstanceType<typeof SubscriptionCategoriesForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  get subscriptionCategory() {
    if (this.editing && this.activeSubscriptionCategory) {
      return this.activeSubscriptionCategory;
    }

    return null;
  }

  get title() {
    return this.editing ? 'subscriptionCategories.edit' : 'subscriptionCategories.create';
  }

  public mounted() {
    if (this.editing) {
      this.$startLoading('subscriptionCategories');
      this.setActive((this.subscriptionCategories as SC[]).find((item: SC) => item._id === this.id)!);
    }
    if (this.editing && !this.activeSubscriptionCategory) {
      this.$router.push({ name: 'subscriptionCategories.index' });
    }
    this.$stopLoading('subscriptionCategories');
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        const data: Partial<SC> = {
          ...this.$refs.form.getData(),
        };
        if (this.editing) {
          await this.update({ _id: this.activeSubscriptionCategory._id, ...data });
        } else {
          await this.store(data);
        }

        if (!this.activeSubscriptionCategory) {
          this.$router.push({ name: 'subscriptionCategories.index' });
          return;
        }
        let formData: FormData;
        const uploadMeta: { _id: string } = { _id: this.activeSubscriptionCategory._id };

        if (data.image && data.image instanceof File) {
          formData = new FormData();
          formData.append('image', data.image as Blob);
          await this.uploadImage({ ...uploadMeta, image: formData });
        }

        this.$router.push({ name: 'subscriptionCategories.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
