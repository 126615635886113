
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StackedForm from '../../mixins/StackedForm';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import DisplayMode from '@/mixins/DisplayMode';
import DisplayIdentifier from '@/mixins/DisplayIdentifier';
import VOpeningHour from '@/components/shared/domain/VOpeningHour.vue';
import { Subscription } from '@/interfaces/models/Subscription';
import { Venue } from '@/interfaces/models/Venue';
import Countries from '@/mixins/Countries';
import CustomerGroup from '@/mixins/CustomerGroup';

@Component({
  components: { VOpeningHour, VFormBuilder },
})
export default class SubscriptionCategoriesForm extends mixins(
  StackedForm,
  DisplayMode,
  DisplayIdentifier,
  Countries,
  CustomerGroup,
) {
  @Prop({ type: Array }) public subscriptions!: Subscription[];
  @Prop({ type: Array }) public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
  };

  get items() {
    return [
      {
        name: 'name',
        type: InputType.Language,
        useTextarea: true,
        label: 'subscriptionCategories.form.name',
        rules: 'required',
      },
      {
        name: 'description',
        type: InputType.Language,
        useTextarea: true,
        label: 'subscriptionCategories.form.description',
      },
      {
        name: 'image',
        type: InputType.File,
        label: 'subscriptionCategories.form.image',
        default: null,
        accept: 'image/*',
      },
      {
        name: 'subscriptions',
        type: InputType.Autocomplete,
        label: 'subscriptionCategories.form.subscriptions',
        items: this.subscriptionItems,
        multiple: true,
      },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'subscriptionCategories.form.venues',
        items: this.venueItems,
        multiple: true,
      },
      {
        name: 'country',
        type: InputType.Select,
        label: 'venue.form.country',
        items: this.countries,
      },
      {
        name: 'customerGroup',
        type: InputType.Select,
        label: 'venue.form.customerGroup',
        items: this.customerGroups,
      },
      {
        name: 'linkedCustomerGroup',
        type: InputType.Select,
        label: 'subscription.form.linkedCustomerGroup',
        items: this.customerGroups,
      },
      {
        name: 'isActive',
        type: InputType.Checkbox,
        label: 'subscriptionCategories.form.isActive',
        default: false,
      },
      {
        name: 'canCombine',
        type: InputType.Checkbox,
        label: 'subscriptionCategories.form.canCombine',
        default: false,
      },
    ];
  }

  get venueItems() {
    if (this.venues && this.venues.length > 0) {
      return this.venues.map((v: Partial<Venue>) => {
        return {
          value: v._id,
          text: v.name,
        };
      });
    } else {
      return [];
    }
  }

  get subscriptionItems() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      return this.subscriptions.map((s: Partial<Subscription>) => {
        return {
          value: s._id,
          text: this.$options.filters!.localized(s.name),
        };
      });
    } else {
      return [];
    }
  }

  public getData() {
    const data: any = { ...this.$refs.form.form };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data.assets === null) {
      delete data.assets;
    }

    // @ts-ignore
    if (this.initialValues) {
      if (
        // @ts-ignore
        this.initialValues.image &&
        // @ts-ignore
        this.initialValues.image.thumb &&
        // @ts-ignore
        this.initialValues.image.thumb.length > 0 &&
        filesToRemove.indexOf('image') > -1
      ) {
        data.image = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    return { ...data };
  }
}
